import React from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
 const pageNumbers = [];

 for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
  pageNumbers.push(i);
 }

 return (
  <nav>
   <ul style={{ display: "flex" }}>
    {pageNumbers.map((number) => (
     <li key={number}>
      <a
       href='#'
       onClick={() => paginate(number)}
       className='btn btn-light btn-sm'>
       {number}
      </a>
     </li>
    ))}
   </ul>
  </nav>
 );
};

export default Pagination;
