import React, {
 useContext,
 useState,
 useEffect,
 useRef,
 useCallback,
} from "react";
import ProfileContext from "../../context/profile/profileContext";
import { Pie, Bar, Line } from "react-chartjs-2";
import {
 Chart as ChartJS,
 CategoryScale,
 LinearScale,
 PointElement,
 LineElement,
 BarElement,
 ArcElement,
 Tooltip,
 Legend,
 registerables as registerablesJS,
} from "chart.js";

ChartJS.register(
 CategoryScale,
 LinearScale,
 PointElement,
 LineElement,
 BarElement,
 ArcElement,
 Tooltip,
 Legend
);

ChartJS.register(...registerablesJS);

const MonthlyExpensesPieChart = ({
 monthlyExpenses,
 paymentAmount,
 selectedPaymentType,
 incomeData,
 liabilities,
 offerStatus,
}) => {
 const chartRef = useRef(null);

 const {
  totalExpenses,
  autoCost,
  housingExpense,
  healthCareExpense,
  livingExpenses,
 } = monthlyExpenses;

 const {
  food,
  housekeepingSupplies,
  apparelAndServices,
  personalCare,
  miscellaneous,
 } = livingExpenses;

 // Calculate the total liabilities from the form response
 const stateTaxPayment = liabilities
  .filter((liability) => liability.type === "State Tax Debt")
  .reduce((sum, liability) => sum + liability.monthlyPayment, 0);
 const alimonyChildSupport = liabilities
  .filter((liability) => liability.type === "Alimony/Child Support")
  .reduce((sum, liability) => sum + liability.monthlyPayment, 0);
 const studentLoans = liabilities
  .filter((liability) => liability.type === "Student Loans")
  .reduce((sum, liability) => sum + liability.monthlyPayment, 0);
 const creditCardPayments = liabilities
  .filter((liability) => liability.type === "Credit Card")
  .reduce((sum, liability) => sum + liability.monthlyPayment, 0);
 const privateLenderLoans = liabilities
  .filter((liability) => liability.type === "Private Lender Loans")
  .reduce((sum, liability) => sum + liability.monthlyPayment, 0);

 // Determine the federal tax payment amount
 const federalTaxPayment =
  offerStatus === "Offer In Compromise" && selectedPaymentType != null
   ? selectedPaymentType
   : Object.values(paymentAmount)[0] || 0;

 // Include the new liabilities in the total expenses
 const expenses =
  Number(totalExpenses || 0) +
  Number(federalTaxPayment) +
  Number(stateTaxPayment) +
  Number(alimonyChildSupport) +
  Number(studentLoans) +
  Number(creditCardPayments) +
  Number(privateLenderLoans);

 const data = {
  labels: [
   "Auto Cost",
   "Housing Expense",
   "Health Care Expense",
   "Food",
   "Housekeeping Supplies",
   "Apparel and Services",
   "Personal Care",
   "Miscellaneous",
   "Federal Tax Payment",
   "State Tax Payment",
   "Alimony/Child Support",
   "Student Loans",
   "Credit Card",
   "Private Lender Loans",
  ],
  datasets: [
   {
    data: [
     autoCost,
     housingExpense,
     healthCareExpense,
     food,
     housekeepingSupplies,
     apparelAndServices,
     personalCare,
     miscellaneous,
     federalTaxPayment,
     stateTaxPayment,
     alimonyChildSupport,
     studentLoans,
     creditCardPayments,
     privateLenderLoans,
    ],
    backgroundColor: [
     "#FF6384",
     "#36A2EB",
     "#FFCE56",
     "#FF4500",
     "#32CD32",
     "#8B008B",
     "#FFA500",
     "#00CED1",
     "#FF1493",
     "#FFD700",
     "#9400D3",
     "#D2691E",
     "#808000",
     "#20B2AA",
    ],
    hoverBackgroundColor: [
     "#FF6384",
     "#36A2EB",
     "#FFCE56",
     "#FF4500",
     "#32CD32",
     "#8B008B",
     "#FFA500",
     "#00CED1",
     "#FF1493",
     "#FFD700",
     "#9400D3",
     "#D2691E",
     "#808000",
     "#20B2AA",
    ],
   },
  ],
 };

 const percentageOfIncome = ((expenses / incomeData.totalIncome) * 100).toFixed(
  2
 );

 const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text:
     "Total Monthly Expenses:" +
     expenses.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
     }),
    position: "top",
    font: {
     size: 30,
    },
   },
   subtitle: {
    display: true,
    text: `${percentageOfIncome}% of your allowable income: ${incomeData.totalIncome.toLocaleString(
     "en-US",
     {
      style: "currency",
      currency: "USD",
     }
    )}`,
    position: "bottom",
    font: {
     size: 40,
    },
   },
   legend: {
    display: false,
    labels: {
     font: {
      size: 15,
     },
    },
   },
  },
 };

 if (offerStatus === "Offer In Compromise" && selectedPaymentType == null) {
  return null; // Display nothing until a payment type is selected
 }

 return (
  <div style={{ width: "333px", height: "333px" }}>
   <Pie ref={chartRef} data={data} options={options} />
  </div>
 );
};

const IncomePortfolioPieChart = ({ income, assets, creditDetails }) => {
 const chartRef = useRef(null);

 const {
  totalIncome,
  totalWages,
  totalPassiveIncome,
  totalRentalIncome,
  totalChildIncome,
 } = income;
 const { totalAccountAssets, totalPropertyAssets } = assets;
 const { totalAvailableCredit } = creditDetails;

 const data = {
  labels: [
   "Salary Income",
   "Passive Income",
   "Rental Income",
   "Child Support / Alimony",
   "Account Assets",
   "Property Assets",
   "Available Credit",
  ],
  datasets: [
   {
    data: [
     totalWages,
     totalPassiveIncome,
     totalRentalIncome,
     totalChildIncome,
     totalAccountAssets,
     totalPropertyAssets,
     totalAvailableCredit,
    ],
    backgroundColor: [
     "#FF6384",
     "#36A2EB",
     "#FFCE56",
     "#32CD32",
     "#8B008B",
     "#FFA07A",
     "#20B2AA",
    ],
    hoverBackgroundColor: [
     "#FF6384",
     "#36A2EB",
     "#FFCE56",
     "#32CD32",
     "#8B008B",
     "#FFA07A",
     "#20B2AA",
    ],
   },
  ],
 };

 const totalValue =
  totalIncome + totalAccountAssets + totalPropertyAssets + totalAvailableCredit;
 const percentageOfIncome = ((totalIncome / totalValue) * 100).toFixed(2);

 const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text:
     "Total Income Portfolio: " +
     totalValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
     }),
    position: "top",
    font: {
     size: 30, // Set the font size for the legend labels
    },
   },
   subtitle: {
    display: true,
    text: `${percentageOfIncome}% from income sources`,
    position: "bottom",
    font: {
     size: 40,
    },
   },
   legend: {
    display: false,
    labels: {
     font: {
      size: 15, // Set the font size for the legend labels
     },
    },
   },
  },
 };

 return (
  <div style={{ width: "333px", height: "333px" }}>
   <Pie ref={chartRef} data={data} options={options} />
  </div>
 );
};

const PlausibleOfferPieChart = ({ plausibleOffer, federalLiability }) => {
 const chartRef = useRef(null);

 let plausible = plausibleOffer + 0.01;
 let remainingLiability = federalLiability - plausible;

 // Handle cases where both values are zero
 if (plausible === 0 && remainingLiability === 0) {
  return (
   <div
    style={{
     width: "333px",
     height: "333px",
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
     textAlign: "center",
    }}>
    <p>No liability to display</p>
   </div>
  );
 }

 const data = {
  labels: ["Plausible Offer", "Remaining Federal Liability"],
  datasets: [
   {
    data: [plausible, remainingLiability],
    backgroundColor: ["#FF6384", "#36A2EB"],
   },
  ],
 };

 const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Plausible Offer vs Federal Liability`,
    font: {
     size: 20, // Set the font size for the legend labels
    },
   },
  },
 };

 return (
  <div style={{ width: "333px", height: "333px" }}>
   <Pie ref={chartRef} data={data} options={options} />
  </div>
 );
};

const OICDownPaymentPieChart = ({
 disposableIncome,
 accountAssets,
 availableCredit,
 requiredDownPayment,
 plausibleOfferAmount,
}) => {
 const availableDownPaymentResources =
  disposableIncome * 3 + accountAssets + availableCredit;

 const remainingDownPayment = Math.max(
  requiredDownPayment - availableDownPaymentResources,
  0
 );
 const coveredDownPayment = Math.min(
  availableDownPaymentResources,
  requiredDownPayment
 );

 const data = {
  labels: ["Covered Down Payment", "Remaining Down Payment"],
  datasets: [
   {
    data: [coveredDownPayment, remainingDownPayment],
    backgroundColor: ["#36A2EB", "#FF6384"],
    borderColor: ["#36A2EB", "#FF6384"],
    borderWidth: 1,
   },
  ],
 };
 const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Available vs Required Down Payment for OIC`,
    font: { size: 20 },
   },
   subtitle: {
    display: true,
    text: `Plausible Offer Amount: ${plausibleOfferAmount.toLocaleString(
     "en-US",
     { style: "currency", currency: "USD" }
    )}`,
    font: { size: 16 },
   },
  },
 };

 return (
  <div style={{ width: "333px", height: "333px" }}>
   <Pie data={data} options={options} />
  </div>
 );
};

const OfferPaymentMethodsPieChart = ({
 plausibleOfferAmount,
 totalFederalLiability,
 disposableIncome,
 accountAssets,
 availableCredit,
 collectionWindow,
 handleClick,
 paymentAmount,
}) => {
 const availableFunds = accountAssets + availableCredit;
 const offerThreshold = 0.8 * plausibleOfferAmount;

 const lumpSumTotal = 6 * disposableIncome + availableFunds;
 const shortTermTotal = 24 * disposableIncome + availableFunds;
 const deferredTotal = collectionWindow * disposableIncome + availableFunds;

 const { LumpSum, ShortTerm, Deferred } = paymentAmount;
 const dataLumpSum = {
  labels: ["Required Funds", "Remaining Funds"],
  datasets: [
   {
    data: [offerThreshold, Math.max(lumpSumTotal - offerThreshold, 0)],
    backgroundColor: ["#FF6384", "#36A2EB"],
    borderColor: ["#FF6384", "#36A2EB"],
    borderWidth: 1,
   },
  ],
 };

 const dataShortTerm = {
  labels: ["Required Funds", "Remaining Funds"],
  datasets: [
   {
    data: [offerThreshold, Math.max(shortTermTotal - offerThreshold, 0)],
    backgroundColor: ["#FF6384", "#36A2EB"],
    borderColor: ["#FF6384", "#36A2EB"],
    borderWidth: 1,
   },
  ],
 };

 const dataDeferred = {
  labels: ["Required Funds", "Remaining Funds"],
  datasets: [
   {
    data: [offerThreshold, Math.max(deferredTotal - offerThreshold, 0)],
    backgroundColor: ["#FF6384", "#36A2EB"],
    borderColor: ["#FF6384", "#36A2EB"],
    borderWidth: 1,
   },
  ],
 };

 const options = (title) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Required vs Available Funds for ${title}`,
    font: { size: 20 },
   },
   subtitle: {
    display: true,
    text: `You must raise ${offerThreshold.toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
    })} to complete your offer. We calculate you can raise ${
     title === "Lump Sum"
      ? lumpSumTotal
      : title === "Short Term"
      ? shortTermTotal
      : deferredTotal
    }.`,
    font: { size: 16 },
   },
  },
 });

 return (
  <div>
   <div className='grid-3'>
    {plausibleOfferAmount / totalFederalLiability <= 0.8 && (
     <div style={{ width: "333px", height: "333px" }}>
      <Pie data={dataLumpSum} options={options("Lump Sum")} />
      <button
       style={{
        backgroundColor: "#007BFF", // Bootstrap primary color
        color: "white",
        border: "none",
        padding: "10px 20px",
        borderRadius: "5px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
       }}
       onClick={() => handleClick(LumpSum)}>
       See Lump Sum Impact
      </button>
     </div>
    )}
    {plausibleOfferAmount / totalFederalLiability <= 0.6 && (
     <div style={{ width: "333px", height: "333px" }}>
      <Pie data={dataShortTerm} options={options("Short Term")} />
      <button
       style={{
        backgroundColor: "#007BFF", // Bootstrap primary color
        color: "white",
        border: "none",
        padding: "10px 20px",
        borderRadius: "5px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
       }}
       onClick={() => handleClick(ShortTerm)}>
       See Short Term Impact
      </button>{" "}
     </div>
    )}
    {plausibleOfferAmount / totalFederalLiability <= 0.4 && (
     <div style={{ width: "333px", height: "333px" }}>
      <Pie data={dataDeferred} options={options("Deferred")} />
      <button
       style={{
        backgroundColor: "#007BFF", // Bootstrap primary color
        color: "white",
        border: "none",
        padding: "10px 20px",
        borderRadius: "5px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
       }}
       onClick={() => handleClick(Deferred)}>
       See Deferred Impact
      </button>{" "}
     </div>
    )}
   </div>
  </div>
 );
};

const OfferDetailsText = ({
 downPayment,
 collectionWindowDetails,
 plausibleOfferAmount,
}) => {
 const currentDate = new Date();
 const lumpSumExpirationDate = new Date(currentDate);
 lumpSumExpirationDate.setMonth(currentDate.getMonth() + 6);

 const shortTermExpirationDate = new Date(currentDate);
 shortTermExpirationDate.setMonth(currentDate.getMonth() + 24);

 const collectionWindowExpirationDate = Math.max(
  ...collectionWindowDetails.expirations
 );

 return (
  <div>
   <h3>Important Offer Details</h3>
   <p>
    To secure your offer, you must make the full down payment of{" "}
    {downPayment.toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
    })}
    . Additionally, you need to fully pay your plausible offer amount of{" "}
    {plausibleOfferAmount.toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
    })}
    .
   </p>
   <p>
    For a Lump Sum Payment, you must pay the balance within 6 months by{" "}
    {lumpSumExpirationDate.toLocaleDateString("en-US")}.
   </p>
   <p>
    For a Short Term Payment Plan, you must pay the balance within 24 months by{" "}
    {shortTermExpirationDate.toLocaleDateString("en-US")}.
   </p>
   <p>
    For a Deferred Payment Plan, you must remain compliant with your tax filings
    until the expiration of the collection window on 12/31/
    {collectionWindowExpirationDate}, or you risk losing your offer status.
   </p>
  </div>
 );
};

const CncCharts = ({
 negativeDisposableIncome,
 disposableIncome,
 accountAssets,
 totalFederalLiability,
 collectionWindow,
 expirations,
 incomeData,
 expensesData,
 formData,
}) => {
 const requiredIncomeThreshold =
  0.05 * (totalFederalLiability / collectionWindow);

 let requiredIncomeIncrease = 0;
 let requiredAssetIncrease = 0;

 // Calculate the required income increase based on total expenses
 const totalExpenses = expensesData.totalExpenses;
 if (totalExpenses > incomeData.totalIncome) {
  requiredIncomeIncrease = totalExpenses - incomeData.totalIncome + 200;
 } else {
  const requiredIncomeToMeetThreshold =
   requiredIncomeThreshold * collectionWindow;
  requiredIncomeIncrease =
   requiredIncomeToMeetThreshold - incomeData.totalIncome;
 }

 // Ensure the required increases are not negative
 requiredIncomeIncrease = Math.max(requiredIncomeIncrease, 0);

 // Calculate the required asset increase based on the provided conditions
 if (
  disposableIncome < requiredIncomeThreshold &&
  accountAssets * 0.8 < 1000 &&
  totalFederalLiability < 50000
 ) {
  requiredAssetIncrease = 1000 - accountAssets;
 } else if (negativeDisposableIncome < 0 && accountAssets < 10000) {
  requiredAssetIncrease = 10000 - accountAssets;
 } else if (disposableIncome < 50) {
  requiredAssetIncrease = 5000 - accountAssets;
 } else if (
  incomeData.totalIncome < 5000 &&
  formData.residents > 3 &&
  accountAssets * 0.8 < 2000
 ) {
  requiredAssetIncrease = 2000 - accountAssets;
 } else {
  requiredAssetIncrease = 0;
 }

 requiredAssetIncrease = Math.max(requiredAssetIncrease, 0);

 const incomeDataChart = {
  labels: ["Current Income", "Required Income Increase"],
  datasets: [
   {
    data: [incomeData.totalIncome, requiredIncomeIncrease],
    backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
    borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
    borderWidth: 1,
   },
  ],
 };

 const incomeOptions = {
  responsive: true,
  layout: { padding: 0 },
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Income increase of ${requiredIncomeIncrease.toFixed(2)}`,
    font: { size: 20 },
   },
   subtitle: {
    display: true,
    text: `Could Result in Losing CNC Status`,
    font: { size: 16 },
   },
  },
 };

 const assetDataChart = {
  labels: ["Current Assets", "Required Asset Increase"],
  datasets: [
   {
    data: [accountAssets, requiredAssetIncrease],
    backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
    borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
    borderWidth: 1,
   },
  ],
 };

 const assetOptions = {
  responsive: true,
  layout: { padding: 0 },
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Asset Increase of ${requiredAssetIncrease.toFixed(2)}`,
    font: { size: 20 },
   },
   subtitle: {
    display: true,
    text: `Could lead to losing CNC status`,
    font: { size: 16 },
   },
  },
 };

 return (
  <div>
   <p className='text-center'>
    Income level maintenance required until 12/31/
    {Math.max(...expirations)}
   </p>
   <div className='grid-2'>
    <div style={{ width: "333px", height: "333px" }}>
     <Pie data={incomeDataChart} options={incomeOptions} />
    </div>
    <div style={{ width: "333px", height: "333px" }}>
     <Pie data={assetDataChart} options={assetOptions} />
    </div>
   </div>
  </div>
 );
};

const FullPayAccountAssetsPieChart = ({
 accountAssets,
 totalFederalLiability,
}) => {
 const requiredReduction = Math.abs(totalFederalLiability - accountAssets) + 1;

 const data = {
  labels: ["Current Assets", "Required Reduction"],
  datasets: [
   {
    data: [accountAssets, requiredReduction],
    backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
    borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
    borderWidth: 1,
   },
  ],
 };

 const options = {
  responsive: true,
  layout: { padding: 0 },
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Required Asset Reduction to Avoid Full Pay`,
    font: { size: 20 },
   },
   subtitle: {
    display: true,
    text: `Reduce assets to below total federal liability`,
    font: { size: 16 },
   },
  },
 };

 return (
  <div style={{ width: "333px", height: "333px" }}>
   <Pie data={data} options={options} />
  </div>
 );
};
const FullPayDisposableIncomePieChart = ({
 disposableIncome,
 totalFederalLiability,
 accountAssets,
}) => {
 const requiredReduction =
  Math.abs(totalFederalLiability - accountAssets - disposableIncome * 12) + 1;

 const data = {
  labels: ["Current Disposable Income", "Required Reduction"],
  datasets: [
   {
    data: [disposableIncome, requiredReduction],
    backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
    borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
    borderWidth: 1,
   },
  ],
 };

 const options = {
  responsive: true,
  layout: { padding: 0 },
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Required Disposable Income Reduction to Avoid Full Pay`,
    font: { size: 20 },
   },
   subtitle: {
    display: true,
    text: `Reduce disposable income to below ${
     (totalFederalLiability - accountAssets) / 12
    }`,
    font: { size: 16 },
   },
  },
 };

 return (
  <div style={{ width: "333px", height: "333px" }}>
   <Pie data={data} options={options} />
  </div>
 );
};

const FullPayExplanation = ({
 propertyAssets,
 totalFederalLiability,
 collectionWindow,
}) => {
 const lastYear = Math.max(...collectionWindow);
 const explanation =
  propertyAssets > totalFederalLiability && totalFederalLiability > 100000
   ? `You have sufficient property assets to cover your total federal liability of $${totalFederalLiability}. To change your situation, you would need to deal with your property ownership.`
   : `You have demonstrated the ability to pay back all of your taxes in full, which means you generally have a year to do so.`;

 return (
  <div>
   <p>{explanation}</p>
   <p>
    To avoid full payment, you would need to adjust your income and savings.
    This is necessary until 12/31/{lastYear}.
   </p>
  </div>
 );
};

const TaxDebtLineGraph = ({
 paymentPlan,
 initialDebt,
 months,
 csedYears,
 status,
 plausibleOfferAmount,
}) => {
 const chartRef = useRef(null);

 const calculateDebtReduction = (
  initialDebt,
  monthlyPayment,
  totalMonths,
  csedYears,
  status,
  plausibleOfferAmount
 ) => {
  let remainingDebt = initialDebt;
  let debtData = [];
  let csedSet = new Set(csedYears);

  for (let month = 1; month <= totalMonths; month++) {
   let currentDate = new Date();
   currentDate.setMonth(currentDate.getMonth() + month);
   let currentYear = currentDate.getFullYear();

   // Check if the current year is a CSED year and ensure it happens only once per year
   if (csedSet.has(currentYear) && month % 12 === 0) {
    remainingDebt *= 0.8;
   } else {
    remainingDebt -= monthlyPayment;
   }

   // Ensure remaining debt doesn't go below the initial debt minus plausible offer for PPIA
   if (status === "PPIA" && month < totalMonths) {
    const minimumDebt = initialDebt - plausibleOfferAmount;
    remainingDebt = Math.max(remainingDebt, minimumDebt);
   }

   debtData.push({
    x: `${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`,
    y: remainingDebt.toFixed(2),
   });
  }

  // Add the final drop-off to zero for PPIA
  if (status === "PPIA") {
   remainingDebt = 0;
   debtData.push({
    x: `${new Date().getMonth() + 1 + totalMonths}/${
     new Date().getFullYear() + Math.floor(totalMonths / 12)
    }`,
    y: remainingDebt.toFixed(2),
   });
  }

  return debtData;
 };

 const monthlyPayment =
  paymentPlan === "6-Year Payment"
   ? initialDebt / 72
   : paymentPlan === "DDIA"
   ? initialDebt / months
   : plausibleOfferAmount / months; // PPIA case

 const debtData = calculateDebtReduction(
  initialDebt,
  monthlyPayment,
  months,
  csedYears,
  status,
  plausibleOfferAmount
 );

 const data = {
  labels: debtData.map((point) => point.x),
  datasets: [
   {
    label: "Remaining Debt",
    data: debtData.map((point) => point.y),
    borderColor: "rgba(75,192,192,1)",
    backgroundColor: "transparent",
    borderWidth: 3,
    pointRadius: 0,
    pointHoverRadius: 0,
    fill: false,
    lineTension: 0.4,
   },
  ],
 };

 const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
   x: {
    display: true,
    title: {
     display: true,
     text: "Date",
     color: "#911",
     font: {
      family: "Times",
      size: 20,
      weight: "bold",
      lineHeight: 1.2,
     },
     padding: { top: 20, left: 0, right: 0, bottom: 0 },
    },
   },
   y: {
    display: true,
    title: {
     display: true,
     text: "Remaining Debt",
     color: "#191",
     font: {
      family: "Times",
      size: 20,
      style: "italic",
      lineHeight: 1.2,
     },
     padding: { top: 30, left: 0, right: 0, bottom: 0 },
    },
   },
  },
  plugins: {
   legend: {
    display: false,
   },
   tooltip: {
    enabled: true,
    callbacks: {
     label: function (context) {
      return `Remaining Debt: ${context.raw}`;
     },
    },
   },
  },
  interaction: {
   mode: "index",
   intersect: false,
  },
  elements: {
   line: {
    borderWidth: 3,
    borderColor: "#777",
    backgroundColor: "rgba(0,0,0,0.4)",
   },
   point: {
    radius: 0,
    hoverRadius: 0,
   },
  },
 };

 return (
  <div style={{ width: "600px", height: "400px" }}>
   <Line data={data} options={options} ref={chartRef} />
  </div>
 );
};
const PaymentAmountToDisposableIncomePieChart = ({
 paymentAmount,
 disposableIncome,
}) => {
 const data = {
  labels: ["Payment Amount", "Disposable Income"],
  datasets: [
   {
    data: [
     Object.values(paymentAmount)[0],
     disposableIncome - Object.values(paymentAmount)[0],
    ],
    backgroundColor: ["#FF6384", "#36A2EB"],
    hoverBackgroundColor: ["#FF6384", "#36A2EB"],
   },
  ],
 };

 const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
   title: {
    display: true,
    text: `Payment Amount vs Disposable Income`,
    font: { size: 20 },
   },
   subtitle: {
    display: true,
    text: `Payment Amount: ${Object.values(paymentAmount)[0].toLocaleString(
     "en-US",
     {
      style: "currency",
      currency: "USD",
     }
    )}, Disposable Income: ${disposableIncome.toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
    })}`,
    font: { size: 16 },
   },
  },
 };

 return (
  <div style={{ width: "333px", height: "333px" }}>
   <Pie data={data} options={options} />
  </div>
 );
};
const PaymentPlanDescriptionText = ({ paymentAmount, months, csedYears }) => {
 const endDate = new Date();
 endDate.setMonth(endDate.getMonth() + months);
 const formattedEndDate = endDate.toLocaleDateString("en-US", {
  year: "numeric",
  month: "long",
 });

 return (
  <div>
   <h3>Payment Plan Details</h3>
   <p>
    Your payment amount is{" "}
    {Object.values(paymentAmount)[0].toLocaleString("en-US", {
     style: "currency",
     currency: "USD",
    })}{" "}
    per month. The length of your payment plan is {months} months, ending on{" "}
    {formattedEndDate}.
   </p>
   {csedYears.length > 0 && (
    <p>
     Note: Your debt will drop off by 20% at the following points due to CSEDs
     (Collection Statute Expiration Dates):{" "}
     {csedYears.map((year, index) => (
      <span key={index}>
       {year}
       {index < csedYears.length - 1 ? ", " : ""}
      </span>
     ))}
    </p>
   )}
  </div>
 );
};
const SettlementChart = () => {
 const {
  settlementCalculationResults,
  clearSettlementCalculation,
  setFormData,
 } = useContext(ProfileContext);
 const [refresh, setRefresh] = useState(false);
 const [selectedPaymentType, setSelectedPaymentType] = useState(null);
 useEffect(() => {
  setRefresh((prevState) => !prevState);
 }, [settlementCalculationResults]);
 const updateOfferPaymentMethod = useCallback((amount) => {
  setSelectedPaymentType(amount);
 }, []);

 const handleRecalculation = (formData) => {
  setFormData(formData);
  clearSettlementCalculation();
 };

 return (
  <div style={{ backgroundColor: "#77d215" }}>
   <span style={{ float: "right" }}>
    {" "}
    <button
     style={{
      backgroundColor: "#007BFF", // Bootstrap primary color
      color: "white",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
     }}
     onClick={() =>
      handleRecalculation(settlementCalculationResults[0].formData)
     }>
     Recalculate Offer
    </button>{" "}
   </span>
   <div>
    {settlementCalculationResults.map((result, index) => {
     return (
      <div key={index}>
       <h1 className='text-center'>{result.offerStatus}</h1>
       <div className='grid-3'>
        <div style={{ width: "333px", height: "333px" }}>
         <MonthlyExpensesPieChart
          key={`monthly-expenses-${index}-${refresh}`}
          monthlyExpenses={result.totalExpensesData}
          paymentAmount={result.paymentAmount}
          selectedPaymentType={selectedPaymentType}
          incomeData={result.incomeData} // Assuming you have income data structured under result
          liabilities={result.formData.otherLiabilities}
          offerStatus={result.offerStatus} // Assuming you have liabilities data structured under result
         />
        </div>
        <div style={{ width: "333px", height: "333px" }}>
         <IncomePortfolioPieChart
          key={`income-portfolio-${index}-${refresh}`}
          income={result.incomeData}
          assets={
           result.assetsDetails || {
            totalAccountAssets: 0,
            totalPropertyAssets: 0,
           }
          }
          creditDetails={result.creditDetails || { totalAvailableCredit: 0 }}
         />
        </div>
        <div style={{ width: "333px", height: "333px" }}>
         <PlausibleOfferPieChart
          key={`plausible-offer-${index}-${refresh}`}
          plausibleOffer={result.plausibleOfferAmount}
          federalLiability={result.liabilitiesData.totalFederalLiability}
         />
        </div>
       </div>
       {result.offerStatus === "CNC" && (
        <div>
         <div className='grid-2'>
          <CncCharts
           negativeDisposableIncome={
            result.collectionAmountDetails.negativeDisposableIncome
           }
           disposableIncome={result.collectionAmountDetails.disposableIncome}
           accountAssets={result.accountAssets}
           totalFederalLiability={result.liabilitiesData.totalFederalLiability}
           collectionWindow={result.collectionWindowDetails.collectionWindow}
           expirations={result.collectionWindowDetails.expirations}
           incomeData={result.incomeData}
           expensesData={result.totalExpensesData}
           formData={result.formData}
          />
          <div
           style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            margin: "10px",
           }}>
           <h2>About CNC Status</h2>
           <p>
            To maintain CNC status, you must keep your income and assets below
            the thresholds that qualified you for this status until 12/31/
            {Math.max(...result.collectionWindowDetails.expirations)} during
            which time you must also remain tax compliant. This means ensuring
            that your disposable income does not increase significantly and that
            your total assets remain low. If you maintain approximately this
            level of income and assets, and ensure the CNC status the tax
            liability you are required to pay is $0.00.
           </p>
          </div>
         </div>
        </div>
       )}

       {result.offerStatus === "Offer In Compromise" && (
        <div className='oic-container'>
         <div className='grid-3 oic-topdiv'>
          <OICDownPaymentPieChart
           disposableIncome={result.collectionAmountDetails.disposableIncome}
           accountAssets={result.assetsDetails.totalAccountAssets}
           availableCredit={result.creditDetails.totalAvailableCredit}
           requiredDownPayment={result.paymentAmount.DownPayment}
           plausibleOfferAmount={result.plausibleOfferAmount}
          />
          <OfferPaymentMethodsPieChart
           plausibleOfferAmount={result.plausibleOfferAmount}
           totalFederalLiability={result.liabilitiesData.totalFederalLiability}
           disposableIncome={result.collectionAmountDetails.disposableIncome}
           accountAssets={result.assetsDetails.totalAccountAssets}
           availableCredit={result.creditDetails.totalAvailableCredit}
           collectionWindow={result.collectionWindowDetails.collectionWindow}
           handleClick={updateOfferPaymentMethod}
           paymentAmount={result.paymentAmount}
          />
          <OfferDetailsText
           downPayment={result.paymentAmount.DownPayment}
           collectionWindowDetails={result.collectionWindowDetails}
           plausibleOfferAmount={result.plausibleOfferAmount}
          />
         </div>
        </div>
       )}
       {result.offerStatus === "DDIA" && (
        <div className='grid-4'>
         <TaxDebtLineGraph
          paymentPlan='DDIA'
          initialDebt={result.liabilitiesData.totalFederalLiability}
          months={result.collectionWindowDetails.collectionWindow}
          csedYears={result.collectionWindowDetails.expirations}
          status={result.offerStatus}
          plausibleOfferAmount={result.plausibleOfferAmount}
         />
         <PaymentAmountToDisposableIncomePieChart
          paymentAmount={result.paymentAmount}
          disposableIncome={result.collectionAmountDetails.disposableIncome}
         />{" "}
         <PaymentPlanDescriptionText
          paymentAmount={result.paymentAmount}
          months={result.collectionWindowDetails.collectionWindow}
          csedYears={result.collectionWindowDetails.expirations}
         />
        </div>
       )}
       {result.offerStatus === "6-Year Payment" && (
        <div className='grid-4 six-yearcontainer'>
         <TaxDebtLineGraph
          paymentPlan='6-Year Payment'
          initialDebt={result.liabilitiesData.totalFederalLiability}
          months={72}
          csedYears={result.collectionWindowDetails.expirations}
          status={result.offerStatus}
          plausibleOfferAmount={result.plausibleOfferAmount}
         />
         <PaymentAmountToDisposableIncomePieChart
          paymentAmount={result.paymentAmount}
          disposableIncome={result.collectionAmountDetails.disposableIncome}
         />
         <PaymentPlanDescriptionText
          paymentAmount={result.paymentAmount}
          months={72}
          csedYears={result.collectionWindowDetails.expirations}
         />
        </div>
       )}
       {result.offerStatus === "Full Pay" && (
        <div>
         <div className='grid-2'>
          <>
           {result.accountAssets >
            result.liabilitiesData.totalFederalLiability && (
            <FullPayAccountAssetsPieChart
             accountAssets={result.accountAssets}
             totalFederalLiability={
              result.liabilitiesData.totalFederalLiability
             }
            />
           )}
           {result.collectionAmountDetails.disposableIncome * 12 >
            result.liabilitiesData.totalFederalLiability -
             result.accountAssets && (
            <FullPayDisposableIncomePieChart
             disposableIncome={result.collectionAmountDetails.disposableIncome}
             totalFederalLiability={
              result.liabilitiesData.totalFederalLiability
             }
             accountAssets={result.accountAssets}
            />
           )}
          </>
          <FullPayExplanation
           propertyAssets={result.assetsDetails.totalPropertyAssets}
           totalFederalLiability={result.liabilitiesData.totalFederalLiability}
           collectionWindow={result.collectionWindowDetails.expirations}
          />
         </div>
        </div>
       )}
       {result.offerStatus === "PPIA" && (
        <div className='grid-4 fullpay-container'>
         <TaxDebtLineGraph
          paymentPlan='PPIA'
          initialDebt={result.liabilitiesData.totalFederalLiability}
          months={result.collectionWindowDetails.collectionWindow}
          csedYears={result.collectionWindowDetails.expirations}
          status={result.offerStatus}
          plausibleOfferAmount={result.plausibleOfferAmount}
         />
         <PaymentAmountToDisposableIncomePieChart
          paymentAmount={result.paymentAmount}
          disposableIncome={result.collectionAmountDetails.disposableIncome}
         />{" "}
         <PaymentPlanDescriptionText
          paymentAmount={result.paymentAmount}
          months={result.collectionWindowDetails.collectionWindow}
          csedYears={result.collectionWindowDetails.expirations}
         />
        </div>
       )}
      </div>
     );
    })}
   </div>
   )
  </div>
 );
};

export default SettlementChart;
