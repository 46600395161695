import React, { useContext, useState, useCallback } from "react";
import ProfileContext from "../../context/profile/profileContext";
import CSVReader from "react-csv-reader";
import {
 Card,
 TextField,
 Button,
 Typography,
 Grid,
 Box,
 IconButton,
 Tooltip,
} from "@mui/material";
import styled from "styled-components";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CreateClientForm from "./CreateClientForm";
const StyledCard = styled(Card)`
 && {
  margin: 20px;
  padding: 20px;
  // Light grey background
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); // Adds depth
  transition: 0.3s;
  &:hover {
   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); // Enhances depth on hover
  }
 }
`;

const CustomTextField = styled(TextField)`
 && {
  margin-bottom: 20px;
 }
`;

const Upload = ({ putState }) => {
 const profileContext = useContext(ProfileContext);
 const { uploadFile, putCanopy, profile } = profileContext;
 const [caseID, setCaseID] = useState("");
 const [file, setFile] = useState("");
 const [useLogics, setUseLogics] = useState(true);
 const [fileData, setFileData] = useState([]); // Now an array to hold multiple files and their corresponding form data

 const handleFileLoaded = (data, fileInfo, index) => {
  // Update the specific file data entry with the loaded data
  const newFileData = [...fileData];
  newFileData[index] = { ...newFileData[index], data };
  setFileData(newFileData);
 };

 const addFileForm = () => {
  // Add a new file form entry
  setFileData([
   ...fileData,
   {
    caseID: "",
    useLogics: true,
    formData: {
     firstName: "",
     lastName: "",
     address: "",
     email: "",
     phone: "",
     SSN: "",
     city: "",
     state: "",
     zip: "",
     addDate: new Date().toISOString().slice(0, 10),
     logicsCaseId: "",
    },
   },
  ]);
 };

 const handleChangeFormData = (index, changes) => {
  // Update the formData for the specific fileData entry by index
  setFileData((currentFileData) =>
   currentFileData.map((entry, idx) => {
    if (idx === index) {
     return {
      ...entry,
      formData: { ...entry.formData, ...changes },
     };
    }
    return entry;
   })
  );
 };

 const toggleForm = (index) => {
  // Toggle the useLogics flag for a specific index
  const newFileData = [...fileData];
  newFileData[index].useLogics = !newFileData[index].useLogics;
  setFileData(newFileData);
 };

 const onSubmit = async (e) => {
  e.preventDefault();
  // Iterate over fileData and upload each one
  fileData.forEach(async (entry) => {
   console.log(entry, "entry");
   if (entry.useLogics) {
    // If using Logics API, only upload the caseID and file
    console.log("Uploading with Logics Case ID:", entry.caseID);
    await uploadFile(entry);
   } else {
    // If not using Logics API, include all formData along with the file
    console.log("Uploading full client data:", entry);
    await uploadFile(entry);
   }
  });
  // Reset fileData state or handle post-upload logic here
 };
 const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  beforeFirstChunk: function (chunk) {
   var index = chunk.match(/\r\n|\r|\n/).index;
   var headings = chunk.substr(0, index).split(",");
   return headings.join() + chunk.substr(index);
  },
  transformHeader: (header) => toCamelCaseString(header),
  transform: (data) => data.toProperCase(),
 };

 String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
   return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
 };
 function convertToString(input) {
  if (input) {
   if (typeof input === "string") {
    return input;
   }

   return String(input);
  }
  return "";
 }

 // convert string to words
 function toWords(input) {
  input = convertToString(input);

  var regex =
   /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;

  return input.match(regex);
 }

 // convert the input array to camel case
 function toCamelCase(inputArray) {
  let result = "";

  for (let i = 0, len = inputArray.length; i < len; i++) {
   let currentStr = inputArray[i];

   let tempStr = currentStr.toLowerCase();

   if (i != 0) {
    // convert first letter to upper case (the word is in lowercase)
    tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
   }

   result += tempStr;
  }

  return result;
 }

 // this function call all other functions

 function toCamelCaseString(header) {
  let words = toWords(header);

  return toCamelCase(words);
 }

 return (
  <StyledCard>
   <Box>
    <Typography variant='h5' gutterBottom>
     Create New Clients
    </Typography>
    <Button onClick={addFileForm} variant='contained' sx={{ mb: 2 }}>
     Add Another Client
    </Button>
    <Grid container spacing={3}>
     {fileData.map((entry, index) => (
      <Grid item xs={12} md={6} key={index}>
       <Box key={index}>
        <Tooltip
         title={
          entry.useLogics
           ? "Manual Client Entry"
           : "Automate Client With Logics"
         }>
         <IconButton onClick={() => toggleForm(index)} sx={{ mb: 2 }}>
          <SwapHorizIcon />
         </IconButton>
        </Tooltip>
        {entry.useLogics ? (
         <CustomTextField
          fullWidth
          label='Logics Case ID'
          variant='outlined'
          name='caseID'
          value={entry.caseID}
          onChange={(e) => handleFileLoaded(index, { caseID: e.target.value })}
         />
        ) : (
         <CreateClientForm
          key={index}
          formData={entry.formData}
          handleChangeFormData={(e) =>
           handleChangeFormData(index, { [e.target.name]: e.target.value })
          }
         />
        )}
        <CSVReader
         label='Upload Account Transcripts'
         parserOptions={papaparseOptions}
         onFileLoaded={(data, fileInfo) =>
          handleFileLoaded(data, fileInfo, index)
         }
         inpuId='profiles'
         inputStyle={{ color: "red" }}
        />
       </Box>
      </Grid>
     ))}
    </Grid>
    <Button
     onClick={onSubmit}
     variant='contained'
     color='primary'
     sx={{ mt: 2 }}>
     Upload All
    </Button>
   </Box>
  </StyledCard>
 );
};

export default Upload;
