export const PROFILE_LOADED = "PROFILE_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const POST_THS = "POST_THS";
export const SET_EMAIL = "SET_EMAIL";
export const POST_EMAIL = "POST_EMAIL";
export const GET_LIBRARY = "GET_LIBRARY";
export const SEND_EMAIL = "SEND_EMAIL";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE ALERT";
export const ADD_CLIENT = "ADD_CLIENT";
export const CLEAR_CLIENT = "CLEAR_CLIENT";
export const PUT_CANOPY = "PUT_CANOPY";
export const GET_PROFILES = "GET_PROFILES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const GET_MESSAGES = "GET_MESSAGES";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const POST_CALC = "POST_CALC";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const FILTER_MESSAGES = "FILTER_MESSAGES";
export const RANGE_MESSAGES = "RANGE_MESSAGES";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const EMAIL_LOADED = "EMAIL_LOADED";
export const EMAIL_CLEARED = "EMAIL_CLEARED";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const SET_PROFILE = "SET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_OTP = "CLEAR_OTP";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILES = "CLEAR_PROFILES";
export const SET_MILESTONES = "SET_MILESTONES";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const TOGGLE_REMEMBER_ME = "TOGGLE_REMEMBER_ME";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const LOAD_CRED = "LOAD_CRED";
export const CLEAR_CRED = "CLEAR_CRED";
export const SET_SETTLEMENT = "SET_SETTLEMENT";
export const CLEAR_SETTLEMENT = "CLEAR_SETTLEMENT";
export const REQUEST_ORGANIZER = "REQUEST_ORGANIZER";
export const TOGGLE_IS_ADMIN = "TOGGLE_IS_ADMIN";
export const TOGGLE_NOT_ADMIN = "TOGGLE_NOT_ADMIN";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const SET_USER_VERIFIED = "SET_USER_VERIFIED";
export const SEND_ADMIN_OTP_SUCCESS = "SEND_ADMIN_OTP_SUCCESS";
export const VERIFY_ADMIN_OTP_SUCCESS = "VERIFY_ADMIN_OTP_SUCCESS";
export const SET_ADMIN_USER_VERIFIED = "SET_ADMIN_USER_VERIFIED";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const GET_ALL_PROFILES_SUCCESS = "GET_ALL_PROFILES_SUCCESS";
export const SET_FORMDATA = "SET_FORMDATA";
export const CLEAR_FORMDATA = "CLEAR_FORMDATA";
