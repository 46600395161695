import React, { useContext } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SettlementForm from "./SettlementForm";
import SettlementChart from "./SettlementChart";
import ProfileContext from "../../context/profile/profileContext";

const SettlementCalculator = () => {
 const { settlementCalculationResults } = useContext(ProfileContext);

 return (
  <Box textAlign='center' padding={3} bgcolor='#eae6de'>
   <Box marginBottom={2}>
    <Typography variant='h6'>Calculate Your Settlement</Typography>
    <Typography variant='h3'>Your Potential Offer In Compromise</Typography>
   </Box>

   <Box
    maxWidth={600}
    margin='auto'
    textAlign='center'
    sx={{
     "@media (max-width: 600px)": {
      textAlign: "left",
     },
    }}>
    <Typography variant='body2'>
     The Tax Track Settlement Calculator is a powerful tool that helps
     individuals assess their eligibility for a potential settlement with the
     IRS. By entering financial information, taxpayers can determine if they
     qualify to settle their tax debt for less than the full amount owed.
     Simplifying the process, it empowers users to explore debt relief options
     confidently.
    </Typography>
   </Box>

   <Box
    padding={2}
    sx={{
     display: "flex", // Makes the Box a flex container
     justifyContent: "center", // Centers content horizontally
     alignItems: "center", // Centers content vertically (if needed)
     width: "100%", // Ensures the Box fills the width of its container
     transition: "all 0.5s ease-in-out",
     margin: "auto", // Adds automatic margins to center the Box in its container
    }}>
    {/* FormContainer */}
    {settlementCalculationResults === null ? (
     <Box>
      <SettlementForm />
     </Box>
    ) : (
     <Box>
      <SettlementChart />
     </Box>
    )}
   </Box>
  </Box>
 );
};

export default SettlementCalculator;
