import React, { useContext, useState, useCallback, useEffect } from "react";
import {
 TextField,
 Button,
 Checkbox,
 IconButton,
 FormControl,
 ListItemText,
 InputLabel,
 Box,
 Grid,
 Select,
 MenuItem,
 FormControlLabel,
 Collapse,
 Typography,
} from "@mui/material";
import {
 ExpandMore,
 ExpandLess,
 Delete,
 Edit,
 VisibilityOff,
} from "@mui/icons-material"; // U
import useExpenseData from "../../utils/useExpenseData";
import TaxLiabilitiesItem from "./TaxLiabilitiesItem";
import ProfileContext from "../../context/profile/profileContext";
import styled, { css } from "styled-components";
const formControlMixin = css`
 width: 100%;
 height: 40px;
 padding: 8px;
 font-size: 0.875rem;
 border-radius: 8px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 background-color: #fff;
 position: relative; /* Add position relative */
 transition: box-shadow 0.3s ease;

 /* Additional styles for TextField and Select */
 & label {
  position: absolute; /* Add position absolute */
  top: -5px; /* Adjust the top value to move the label above the input */
  left: -5px; /* Adjust the left value to move the label to the left */
  font-size: 1rem;
 }

 & legend {
  font-size: 0.6rem;
 }

 &:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
 }
`;

const SettlementFormContainer = styled.div`
 background-color: #f9f9f9;
 overflow-x: hidden;
 width: 100%; // Ensures it fills the container
 max-width: 1200px; // Increased for larger displays
 border-radius: 16px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 padding: 16px;
 overflow: auto;
 transform-style: preserve-3d;
 backface-visibility: hidden;
 transform-origin: center;
 transition: transform 0.5s ease, max-width 0.5s ease; // Smooth transition for width changes
`;

const SettlementFormInputLabel = styled(InputLabel)`
 color: #3f51b5;
 font-size: 16px;
 margin-bottom: 5px;
`;
const SettlementFormSectionTitle = styled(Typography)`
 color: #3f51b5;
`;

const SettlementFormControlFullWidth = styled(FormControl)`
 ${formControlMixin}
 width: 100%; // Ensures full width usage
`;
const SettlementFormTextField = styled(TextField)`
 ${formControlMixin}
 & .MuiInputBase-root, & .MuiInputBase-input {
  height: 40px;
  box-shadow: none;
 }
`;

const SettlementFormButton = styled(Button)`
 margin-top: 10px;
 width: 100%; // Full width to align with other inputs
 font-size: 0.875rem;
`;

const SettlementFormSelect = styled(Select)`
 ${formControlMixin}
 width: 100%; // Ensures select fields also expand fully
`;

const SettlementFormSectionContainer = styled(Grid)`
 display: grid;
 place-items: center; // Center the content both horizontally and vertically
 gap: 16px; // Add spacing of 16px between items
 padding: 16px; // Add padding to the box
`;

const SettlementFormExpenseGridFormControl = styled(FormControl)`
 width: 100%; // Make sure the form control takes full width
 margin-right: 16px;
 margin-top: 5px;
`;
const SettlementForm = () => {
 const profileContext = useContext(ProfileContext);
 const { setSettlementCalculation, formData, clearFormData } = profileContext;
 const { housing, auto, healthCareExpenses, livingExpenses, states } =
  useExpenseData();

 const [formVisible, setFormVisible] = useState(true);
 const [formResponse, setFormResponse] = useState({
  taxLiabilities: [
   { plaintiff: "irs", amount: 0, payment: 0, years: [], unfiledYears: [] },
  ],
  incomes: [{ type: "", amount: 0 }],
  state: "",
  carsOwned: 0,
  assets: [{ name: "", estimatedValue: 0, liabilityOnAsset: 0 }],
  otherLiabilities: [
   {
    type: "",
    totalLiability: 0,
    monthlyPayment: 0,
    cappedValue: 0,
    availableCredit: 0,
   },
  ],
  residents: 0,
  residents65: 0,
 });

 useEffect(() => {
  if (formData != null) {
   setFormResponse(formData);
   clearFormData();
  }
 }, [formData]);
 const handleAddLiability = () => {
  setFormResponse((prev) => ({
   ...prev,
   otherLiabilities: [
    ...prev.otherLiabilities,
    {
     type: "",
     totalLiability: 0,
     monthlyPayment: 0,
     cappedValue: 0,
     availableCredit: 0,
    },
   ],
  }));
 };

 const handleDeleteLiability = (index) => {
  const updatedLiabilities = [...formResponse.otherLiabilities];
  updatedLiabilities.splice(index, 1);
  setFormResponse({ ...formResponse, otherLiabilities: updatedLiabilities });
 };

 const handleLiabilitiesInputChange = (event, index) => {
  const { name, value } = event.target;
  const updatedLiabilities = [...formResponse.otherLiabilities];
  updatedLiabilities[index] = { ...updatedLiabilities[index], [name]: value };
  setFormResponse({ ...formResponse, otherLiabilities: updatedLiabilities });
 };

 const handleAddAsset = () => {
  setFormResponse((prev) => ({
   ...prev,
   assets: [
    ...prev.assets,
    { type: "", estimatedValue: 0, liabilityOnAsset: 0 },
   ],
  }));
 };

 const handleDeleteAsset = (index) => {
  const updatedAssets = [...formResponse.assets];
  updatedAssets.splice(index, 1);
  setFormResponse({ ...formResponse, assets: updatedAssets });
 };

 const handleAssetsInputChange = (event, index) => {
  const { name, value } = event.target;
  const updatedAssets = [...formResponse.assets];
  updatedAssets[index] = { ...updatedAssets[index], [name]: value };
  setFormResponse({ ...formResponse, assets: updatedAssets });
 };

 function calculateTotalExpenses() {
  const { state, carsOwned, residents, residents65 } = formResponse;

  const region = Object.values(auto).find((region) =>
   region.states.includes(state)
  );
  const autoCost = region
   ? carsOwned > 1
     ? region.twoCars
     : region.oneCar
   : 0;

  const housingCost = housing.find((item) => item.state === state);
  const familySize = `familyOf${residents}`;
  const housingExpense = housingCost ? housingCost[familySize] : 0;

  const residentsUnder65 = parseInt(residents) - (parseInt(residents65) || 0);

  const healthCareExpense =
   healthCareExpenses.under65 * parseInt(residentsUnder65) +
   healthCareExpenses.over65 * parseInt(residents65);

  const livingExpensesData = livingExpenses[familySize];

  const totalExpenses =
   autoCost + housingExpense + healthCareExpense + livingExpensesData.total;

  return {
   totalExpenses,
   autoCost,
   housingExpense,
   healthCareExpense,
   livingExpenses: livingExpensesData,
  };
 }
 function calculateTotalLiabilities() {
  const { otherLiabilities, taxLiabilities } = formResponse;

  const totalFederalLiability = taxLiabilities.reduce(
   (sum, liability) => sum + parseFloat(liability.amount),
   0
  );

  let stateTaxPayment = otherLiabilities
   .filter((liability) => liability.type === "State Tax Debt")
   .reduce((sum, liability) => sum + parseFloat(liability.monthlyPayment), 0);

  let stateTaxLiability = otherLiabilities
   .filter((liability) => liability.type === "State Tax Debt")
   .reduce((sum, liability) => sum + parseFloat(liability.totalLiability), 0);

  // Set default state payment if no payment is entered but there is a notable state liability
  if (stateTaxPayment === 0 && stateTaxLiability > 5000) {
   stateTaxPayment = 50; // Default payment
  }

  const nonStateLiabilities = otherLiabilities
   .filter((liability) => liability.type !== "State Tax Debt")
   .reduce((sum, liability) => sum + parseFloat(liability.monthlyPayment), 0);

  // Implement logic for capping non-state liabilities if necessary
  // Example placeholder for further calculation

  return {
   totalFederalLiability,
   stateTaxLiability,
   stateTaxPayment,
   nonStateLiabilities,
  };
 }

 function calculateCollectionWindow() {
  const { taxLiabilities } = formResponse;

  const csedYears = taxLiabilities
   .filter((f) => f.plaintiff === "irs")
   .flatMap((liability) => liability.years.map((year) => parseInt(year) + 10));

  const lastCsedYear = Math.max(...csedYears);

  const currentDate = new Date();
  const lastCsedDate = new Date(lastCsedYear, 11, 31); // December 31 of the last CSED year
  const monthsDiff =
   (lastCsedDate.getFullYear() - currentDate.getFullYear()) * 12 +
   (lastCsedDate.getMonth() - currentDate.getMonth());

  return { expirations: csedYears, collectionWindow: monthsDiff };
 }

 function calculateTotalIncome() {
  const { incomes } = formResponse;

  const totalWages =
   incomes
    .filter((income) => income.type === "salary")
    .reduce((sum, income) => sum + parseFloat(income.amount), 0) * 0.8;

  const totalPassiveIncome =
   incomes
    .filter((income) => income.type === "passive")
    .reduce((sum, income) => sum + parseFloat(income.amount), 0) * 0.8;

  const totalRentalIncome =
   incomes
    .filter((income) => income.type === "rental")
    .reduce((sum, income) => sum + parseFloat(income.amount), 0) * 0.8;

  const totalChildIncome =
   incomes
    .filter((income) => income.type === "child")
    .reduce((sum, income) => sum + parseFloat(income.amount), 0) * 0.8;

  const totalIncome =
   totalWages + totalPassiveIncome + totalChildIncome + totalRentalIncome;

  return {
   totalIncome,
   totalWages,
   totalPassiveIncome,
   totalChildIncome,
   totalRentalIncome,
  };
 }

 function calculateMonthlyCollectionAmount() {
  const totalExpenses = calculateTotalExpenses().totalExpenses;
  const { totalIncome } = calculateTotalIncome();
  const { nonStateLiabilities, stateTaxPayment } = calculateTotalLiabilities();

  // Available income after basic expenses

  console.log(totalIncome);
  console.log(totalExpenses);
  console.log(nonStateLiabilities);
  let disposableIncome = totalIncome - totalExpenses - nonStateLiabilities;

  // Store negative disposable income separately
  const negativeDisposableIncome = Math.min(disposableIncome, 0);

  // Ensure disposable income is not negative
  disposableIncome = Math.max(disposableIncome, 0);

  // Cap state tax liability at 95% of disposable income
  const cappedStateTaxPayment = Math.min(
   stateTaxPayment,
   disposableIncome * 0.95
  );

  // Total liabilities cannot exceed the disposable income
  const monthlyCollectionAmount = Math.max(
   disposableIncome - cappedStateTaxPayment,
   0
  );

  return {
   monthlyCollectionAmount,
   cappedStateTaxPayment,
   disposableIncome,
   negativeDisposableIncome,
  };
 }

 function calculatePlausibleOfferAmount() {
  const monthlyCollectionAmount =
   calculateMonthlyCollectionAmount().monthlyCollectionAmount;
  const collectionWindow = calculateCollectionWindow().collectionWindow;
  const plausibleOfferAmount = monthlyCollectionAmount * collectionWindow;

  return plausibleOfferAmount;
 }
 function calculateAndAssessAssets() {
  const { assets } = formResponse;
  const { totalFederalLiability } = calculateTotalLiabilities();
  const plausibleOfferAmount = calculatePlausibleOfferAmount();

  let totalAccountAssets = 0;
  let totalPropertyAssets = 0;

  assets.forEach((asset) => {
   const netValue = asset.estimatedValue - asset.liabilityOnAsset;
   if (
    ["Savings Account", "Retirement Account", "Stocks and Bonds"].includes(
     asset.type
    )
   ) {
    totalAccountAssets += netValue;
   } else {
    totalPropertyAssets += netValue;
   }
  });

  return {
   totalAccountAssets,
   totalPropertyAssets,
  };
 }

 function considerAvailableCredit() {
  const totalAvailableCredit = formResponse.otherLiabilities
   .filter((liability) => liability.type === "Credit Card")
   .reduce((sum, liability) => sum + parseFloat(liability.availableCredit), 0);

  return {
   totalAvailableCredit,
  };
 }

 function determineSettlementCalculation(formData) {
  const collectionWindowDetails = calculateCollectionWindow(formData);
  const plausibleOfferAmount = calculatePlausibleOfferAmount(formData);
  const totalExpensesData = calculateTotalExpenses(formData);
  const liabilitiesData = calculateTotalLiabilities(formData);
  const collectionAmountDetails = calculateMonthlyCollectionAmount(formData);
  const incomeData = calculateTotalIncome(formData);
  const assetsDetails = calculateAndAssessAssets(formData);
  const creditDetails = considerAvailableCredit(formData);
  const accountAssets =
   assetsDetails.totalAccountAssets + creditDetails.totalAvailableCredit;
  const propertyAssets = assetsDetails.totalPropertyAssets;
  const { totalFederalLiability, stateTaxLiability } = liabilitiesData;
  const { collectionWindow } = collectionWindowDetails;
  const { disposableIncome, negativeDisposableIncome } =
   collectionAmountDetails;
  const savings = assetsDetails.totalAccountAssets;
  let settlementCalculationResults = [];
  console.log(stateTaxLiability);
  const determineStatus = (
   plausibleOfferAmount,
   totalFederalLiability,
   negativeDisposableIncome,
   accountAssets,
   propertyAssets,
   collectionWindow,
   disposableIncome
  ) => {
   if (
    propertyAssets * 0.8 > totalFederalLiability &&
    totalFederalLiability > 100000
   ) {
    return "Full Pay";
   }

   if (disposableIncome * 12 > totalFederalLiability - accountAssets) {
    return "Full Pay";
   }

   if (accountAssets * 0.8 > totalFederalLiability) {
    return "Full Pay";
   }

   if (
    accountAssets * 0.8 + plausibleOfferAmount + negativeDisposableIncome >
     2 * totalFederalLiability &&
    collectionWindow < 24
   ) {
    return "Full Pay";
   }
   if (
    accountAssets * 0.8 > totalFederalLiability * 0.8 &&
    disposableIncome * 12 > totalFederalLiability * 0.7
   ) {
    return "Full Pay";
   }
   if (
    plausibleOfferAmount < 0.049 * totalFederalLiability &&
    accountAssets * 0.8 < 1000 &&
    totalFederalLiability < 50000
   ) {
    return "CNC";
   }

   if (negativeDisposableIncome < 0 && accountAssets < 10000) {
    return "CNC";
   }

   if (disposableIncome < 50) {
    return "CNC";
   }

   if (
    incomeData.totalIncome < 5000 &&
    formData.residents > 3 &&
    accountAssets * 0.8 < 2000
   ) {
    return "CNC";
   }

   if (accountAssets + plausibleOfferAmount > 1.5 * totalFederalLiability) {
    return "6-Year Payment";
   }

   if (disposableIncome * 72 > totalFederalLiability - accountAssets) {
    return "6-Year Payment";
   }

   if (
    accountAssets + plausibleOfferAmount + negativeDisposableIncome >
     1.5 * totalFederalLiability &&
    accountAssets + plausibleOfferAmount + negativeDisposableIncome <
     2 * totalFederalLiability
   ) {
    return "6-Year Payment";
   }

   if (
    accountAssets * 0.8 + plausibleOfferAmount + negativeDisposableIncome >
     0.8 * totalFederalLiability &&
    accountAssets * 0.8 + plausibleOfferAmount + negativeDisposableIncome <
     1.5 * totalFederalLiability
   ) {
    return "DDIA";
   }

   if (disposableIncome > 250 && collectionWindow > 72) {
    return "DDIA";
   }

   if (
    accountAssets * 0.8 > 0.2 * plausibleOfferAmount &&
    plausibleOfferAmount < 0.8 * totalFederalLiability
   ) {
    return "Offer In Compromise";
   }

   if (
    accountAssets * 0.8 < totalFederalLiability * 0.3 &&
    plausibleOfferAmount < totalFederalLiability * 0.5 &&
    totalFederalLiability > 100000
   ) {
    return "Offer In Compromise";
   }

   if (
    accountAssets * 0.8 + disposableIncome * 6 > plausibleOfferAmount * 0.2 &&
    plausibleOfferAmount < totalFederalLiability * 0.8
   ) {
    return "Offer In Compromise";
   }
   if (
    accountAssets * 0.8 < 0.2 * plausibleOfferAmount &&
    disposableIncome > 0 &&
    plausibleOfferAmount < 0.8 * totalFederalLiability
   ) {
    return "Offer In Compromise";
   }

   if (
    accountAssets === 0 &&
    plausibleOfferAmount < 5000 &&
    plausibleOfferAmount > 0.05 * totalFederalLiability &&
    disposableIncome > 0
   ) {
    return "Offer In Compromise";
   }

   return "PPIA";
  };

  const determinePaymentType = (status) => {
   if (status === "Full Pay") {
    return ["Lump Sum"];
   } else if (status === "DDIA" || status === "6-Year Payment") {
    return ["Payment Plan"];
   } else if (status === "Offer In Compromise") {
    return ["Lump Sum", "Short Term", "Deferred"];
   }
   return ["Partial Payment Plan"];
  };

  const determinePaymentAmount = (
   status,
   totalFederalLiability,
   collectionWindow,
   accountAssets,
   negativeDisposableIncome,
   plausibleOfferAmount,
   disposableIncome,
   availableCredit,
   propertyAssets
  ) => {
   const maxPaymentAmount = disposableIncome * 0.95;

   if (status === "Full Pay") {
    return {
     FullPay: (totalFederalLiability - accountAssets) / 12,
    };
   } else if (status === "DDIA") {
    const paymentAmount =
     (totalFederalLiability - accountAssets) / collectionWindow;
    return {
     PaymentPlan: Math.min(paymentAmount, maxPaymentAmount),
    };
   } else if (status === "6-Year Payment") {
    return {
     PaymentPlan: (totalFederalLiability - accountAssets) / 72,
    };
   } else if (status === "Offer In Compromise") {
    const downPayment = plausibleOfferAmount * 0.2;
    const downPaymentResources =
     disposableIncome * 3 + accountAssets + availableCredit + propertyAssets;
    const downPaymentBalance = downPayment - downPaymentResources;

    // Calculate payment adjustments based on different terms
    const lumpSumAdjustment =
     downPaymentBalance < 0 ? Math.abs(downPaymentBalance) / 6 : 0;
    const shortTermAdjustment =
     downPaymentBalance < 0 ? Math.abs(downPaymentBalance) / 24 : 0;
    const deferredAdjustment =
     downPaymentBalance < 0
      ? Math.abs(downPaymentBalance) / collectionWindow
      : 0;

    return {
     LumpSum: (plausibleOfferAmount * 0.8) / 5 - lumpSumAdjustment,
     ShortTerm: (plausibleOfferAmount * 0.8) / 24 - shortTermAdjustment,
     Deferred:
      (plausibleOfferAmount * 0.8) / collectionWindow - deferredAdjustment,
     DownPayment: downPayment,
     DownPaymentResources: downPaymentResources,
     DownPaymentBalance: downPaymentBalance,
    };
   }

   const ppiaAmount =
    (plausibleOfferAmount + accountAssets + negativeDisposableIncome) /
    collectionWindow;
   return {
    PPIA: Math.min(ppiaAmount, maxPaymentAmount),
   };
  };

  function calculatePossibleStatePayments(formData) {
   const collectionDetails = calculateMonthlyCollectionAmount(formData);
   const { disposableIncome } = collectionDetails;
   const { stateTaxLiability, stateTaxPayment } =
    calculateTotalLiabilities(formData);
   const collectionWindow =
    calculateCollectionWindow(formData).collectionWindow;
   const plausibleOfferAmount = calculatePlausibleOfferAmount(formData);
   const assetsDetails = calculateAndAssessAssets(formData);
   const creditDetails = considerAvailableCredit(formData);
   const accountAssets =
    assetsDetails.totalAccountAssets + creditDetails.totalAvailableCredit;
   const propertyAssets = assetsDetails.totalPropertyAssets;
   const liabilitiesData = calculateTotalLiabilities(formData);
   const { totalFederalLiability } = liabilitiesData;
   const { negativeDisposableIncome } =
    calculateMonthlyCollectionAmount(formData);

   let newOfferBrackets = [];
   let previousDesignation = null;

   const minStatePayment = 50;
   const maxStatePayment = Math.min(
    stateTaxLiability / collectionWindow,
    0.95 * disposableIncome
   );

   let incrementedStatePayment = Math.max(stateTaxPayment, minStatePayment);

   while (incrementedStatePayment <= maxStatePayment) {
    const newMonthlyCollectionAmount =
     disposableIncome - incrementedStatePayment;
    const newPlausibleOffer = newMonthlyCollectionAmount * collectionWindow;

    const newDesignation = determineStatus(
     newPlausibleOffer,
     totalFederalLiability,
     negativeDisposableIncome,
     accountAssets,
     propertyAssets,
     collectionWindow
    );

    if (newDesignation !== previousDesignation) {
     newOfferBrackets.push({
      newMonthlyStatePayment: incrementedStatePayment,
      newPlausibleOffer,
      designation: newDesignation,
     });
     previousDesignation = newDesignation;
    }

    incrementedStatePayment += 50;
   }

   return newOfferBrackets;
  }

  const offerStatus = determineStatus(
   plausibleOfferAmount,
   totalFederalLiability,
   negativeDisposableIncome,
   accountAssets,
   propertyAssets,
   disposableIncome
  );
  const paymentType = determinePaymentType(offerStatus);
  const paymentAmount = determinePaymentAmount(
   offerStatus,
   totalFederalLiability,
   collectionWindow,
   accountAssets,
   negativeDisposableIncome,
   plausibleOfferAmount,
   disposableIncome
  );

  console.log(plausibleOfferAmount, "plausible");
  settlementCalculationResults.push({
   offerStatus,
   paymentType,
   paymentAmount,
   plausibleOfferAmount,
   formData,
   incomeData,
   totalExpensesData,
   collectionAmountDetails,
   collectionWindowDetails,
   liabilitiesData,
   assetsDetails,
   creditDetails,
   accountAssets,
   disposableIncome,
  });

  const stateAdjustments =
   stateTaxLiability > 5000 ? calculatePossibleStatePayments() : [];

  stateAdjustments.forEach((adj) => {
   const adjustedOfferAmount =
    plausibleOfferAmount - adj.newMonthlyStatePayment * collectionWindow;
   const adjustedOfferStatus = determineStatus(
    adjustedOfferAmount,
    totalFederalLiability,
    negativeDisposableIncome,
    accountAssets,
    propertyAssets
   );
   const adjustedPaymentType = determinePaymentType(adjustedOfferStatus);
   const adjustedPaymentAmount = determinePaymentAmount(
    adjustedOfferStatus,
    totalFederalLiability,
    collectionWindow,
    accountAssets,
    negativeDisposableIncome,
    adjustedOfferAmount,
    disposableIncome
   );

   settlementCalculationResults.push({
    offerStatus: adjustedOfferStatus,
    paymentType: adjustedPaymentType,
    paymentAmount: adjustedPaymentAmount,
    plausibleOfferAmount: adjustedOfferAmount,
    formData,
    incomeData,
    totalExpensesData,
    collectionAmountDetails,
    collectionWindowDetails,
    liabilitiesData,
    assetsDetails,
    creditDetails,
    accountAssets,
    newMonthlyStatePayment: adj.newMonthlyStatePayment,
    adjustedOfferAmount,
   });
  });

  return settlementCalculationResults;
 }

 const handleSubmit = (e) => {
  e.preventDefault();

  const settlementCalculationResults =
   determineSettlementCalculation(formResponse);

  console.log(settlementCalculationResults);

  setSettlementCalculation(settlementCalculationResults);
 };

 const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormResponse((prevFormResponse) => ({
   ...prevFormResponse,
   [name]: value,
  }));
 };

 const handleTaxLiabilitiesInputChange = (e, index) => {
  const { name, value } = e.target;
  if (name === "year") {
   // Convert the selected options to an array of values
   const selectedYears = Array.from(
    e.target.selectedOptions,
    (option) => option.value
   );

   setFormResponse((prevState) => ({
    ...prevState,
    taxLiabilities: prevState.taxLiabilities.map((debt, idx) =>
     idx === index ? { ...debt, year: selectedYears } : debt
    ),
   }));
  } else {
   const { name, value } = e.target;
   setFormResponse((prevFormResponse) => {
    const updatedTaxLiabilities = [...prevFormResponse.taxLiabilities];
    updatedTaxLiabilities[index][name] = value;
    return {
     ...prevFormResponse,
     taxLiabilities: updatedTaxLiabilities,
    };
   });
  }
 };

 const handleAddIncome = () => {
  setFormResponse((prevFormResponse) => ({
   ...prevFormResponse,
   incomes: [...prevFormResponse.incomes, { type: "", amount: 0 }],
  }));
 };

 const handleIncomeInputChange = (e, index) => {
  const { name, value } = e.target;
  setFormResponse((prevFormResponse) => {
   const updatedIncomes = [...prevFormResponse.incomes];
   updatedIncomes[index][name] = value;
   return {
    ...prevFormResponse,
    incomes: updatedIncomes,
   };
  });
 };

 const handleDeleteIncome = (index) => {
  const updatedIncomeList = [...formResponse.incomes];
  updatedIncomeList.splice(index, 1);
  setFormResponse({ ...formResponse, incomes: updatedIncomeList });
 };

 const handleUnfiledYearsChange = (year) => {
  setFormResponse((prevResponse) => {
   const updatedTaxLiabilities = prevResponse.taxLiabilities.map((debt) => {
    if (debt.unfiledYears.includes(year)) {
     const updatedUnfiledYears = debt.unfiledYears.filter(
      (unfiledYear) => unfiledYear !== year
     );
     return {
      ...debt,
      unfiledYears: updatedUnfiledYears,
      years: [...debt.years, year],
     };
    } else if (debt.years.includes(year)) {
     const updatedYears = debt.years.filter((filedYear) => filedYear !== year);
     return {
      ...debt,
      unfiledYears: [...debt.unfiledYears, year],
      years: updatedYears,
     };
    }
    return debt;
   });

   return {
    ...prevResponse,
    taxLiabilities: updatedTaxLiabilities,
   };
  });
 };
 const currentYear = new Date().getFullYear();
 const startYear = 2010;
 const years = Array.from(
  { length: currentYear - startYear + 1 },
  (_, index) => startYear + index
 );

 return (
  <SettlementFormContainer formVisible={formVisible}>
   <form onSubmit={handleSubmit}>
    <SettlementFormSectionContainer>
     <SettlementFormSectionTitle variant='h5'>
      Settlement Estimate Calculator
     </SettlementFormSectionTitle>
    </SettlementFormSectionContainer>
    <Grid container spacing={3}>
     <Grid item xs={12} md={4}>
      {" "}
      <SettlementFormSectionContainer>
       <SettlementFormSectionTitle shrink>Tax Debt</SettlementFormSectionTitle>

       {formResponse.taxLiabilities.map((debt, index) => (
        <Box key={index} marginBottom={2}>
         <Grid spacing={2} container>
          <Grid item xs={5.65}>
           <SettlementFormControlFullWidth>
            <SettlementFormInputLabel shrink>
             Plaintiff
            </SettlementFormInputLabel>
            <SettlementFormSelect
             disabled
             name='plaintiff'
             value={debt.plaintiff}
             onChange={(e) => handleTaxLiabilitiesInputChange(e, index)}
             displayEmpty>
             <MenuItem value='irs'>IRS</MenuItem>
            </SettlementFormSelect>
           </SettlementFormControlFullWidth>
          </Grid>
          <Grid item xs={5.65}>
           <SettlementFormControlFullWidth>
            <SettlementFormTextField
             name='amount'
             required
             label='Amount'
             value={debt.amount}
             onChange={(e) => handleTaxLiabilitiesInputChange(e, index)}
            />
           </SettlementFormControlFullWidth>
          </Grid>
         </Grid>
         <Box marginTop={2}>
          <Grid container>
           <Grid item xs={11.25}>
            {" "}
            <SettlementFormControlFullWidth>
             <SettlementFormInputLabel shrink>
              Years With Debt
             </SettlementFormInputLabel>
             <SettlementFormSelect
              name='years'
              displayEmpty
              required
              multiple
              value={
               Array.from(new Set([...debt.years, ...debt.unfiledYears])).sort(
                (a, b) => a - b
               ) || []
              }
              onChange={(e) => handleTaxLiabilitiesInputChange(e, index)}
              renderValue={(selected) => selected.join(", ") || "Select years"}>
              {years.map((year) => (
               <MenuItem
                key={year}
                value={year}
                onClick={() => handleUnfiledYearsChange(year)}
                style={{
                 backgroundColor: debt.unfiledYears?.includes(year)
                  ? "#FFCDD2" // Unfiled year color (red)
                  : debt.years?.includes(year)
                  ? "#F5F5F5" // Filed year color (light neutral)
                  : "inherit", // Default background color
                }}>
                <ListItemText
                 primary={year}
                 secondary={
                  debt.years?.includes(year) &&
                  !debt.unfiledYears?.includes(year)
                   ? "Filed"
                   : debt.unfiledYears?.includes(year)
                   ? "Unfiled"
                   : ""
                 }
                />
               </MenuItem>
              ))}
             </SettlementFormSelect>
            </SettlementFormControlFullWidth>
           </Grid>
          </Grid>
         </Box>
        </Box>
       ))}
      </SettlementFormSectionContainer>
      <SettlementFormSectionContainer>
       <SettlementFormSectionTitle shrink>
        Monthly Income (Pre-tax)
       </SettlementFormSectionTitle>
       {formResponse.incomes.map((income, index) => (
        <Box key={index}>
         <Grid
          container
          required
          alignItems='center'
          spacing={2}
          key={index}
          direction='row'>
          <Grid item xs={5.35}>
           {/* Set xs to 8 to make the inputs take up 8 columns (80% of the width) */}
           <SettlementFormControlFullWidth>
            <SettlementFormInputLabel shrink>Type</SettlementFormInputLabel>
            <SettlementFormSelect
             name='type'
             label='Type'
             required
             value={income.type}
             onChange={(e) => handleIncomeInputChange(e, index)}>
             <MenuItem value='salary'>Salary and Wages</MenuItem>
             <MenuItem value='passive'>
              Disbursements(include SSI and Retirement)
             </MenuItem>
             <MenuItem value='child'>Child Support and Alimony</MenuItem>
             <MenuItem value='rental'>Rental Properties</MenuItem>
            </SettlementFormSelect>
           </SettlementFormControlFullWidth>
          </Grid>
          <Grid item xs={5.35}>
           {/* Set xs to 4 to make the input take up 4 columns (40% of the width) */}
           <SettlementFormControlFullWidth>
            <SettlementFormTextField
             name='amount'
             label='Amount'
             required
             value={income.amount}
             onChange={(e) => handleIncomeInputChange(e, index)}
            />
           </SettlementFormControlFullWidth>
          </Grid>
          <Grid item xs={1}>
           {/* Set xs to 4 to make the input take up 4 columns (40% of the width) */}
           <Delete
            style={{ fontSize: "1.4rem", float: "right", marginTop: "10px" }}
            onClick={() => handleDeleteIncome(index)}
           />
          </Grid>
         </Grid>
        </Box>
       ))}
       <SettlementFormButton variant='outlined' onClick={handleAddIncome}>
        Add Income
       </SettlementFormButton>
      </SettlementFormSectionContainer>
      <SettlementFormSectionContainer>
       <Box>
        <SettlementFormSectionTitle shrink>Expenses</SettlementFormSectionTitle>
       </Box>
       <Grid container spacing={1} alignItems='center'>
        <Grid item xs={11.25}>
         {" "}
         {/* This Grid item will take up the full width */}
         <SettlementFormControlFullWidth>
          <SettlementFormInputLabel shrink>State</SettlementFormInputLabel>
          <SettlementFormSelect
           value={formResponse.state}
           displayEmpty
           required
           name='state'
           onChange={handleInputChange}
           inputProps={{ "aria-label": "Select State" }}>
           {states.map((state) => (
            <MenuItem key={state.abbreviation} value={state.abbreviation}>
             {state.longName}
            </MenuItem>
           ))}
          </SettlementFormSelect>
         </SettlementFormControlFullWidth>
        </Grid>
        <Grid xs={3.75} marginTop={1} item>
         <SettlementFormControlFullWidth>
          <SettlementFormInputLabel shrink>Cars</SettlementFormInputLabel>
          <SettlementFormSelect
           labelId='cars-owned-label'
           id='cars-owned-select'
           name='carsOwned'
           required
           value={formResponse.carsOwned}
           onChange={handleInputChange}>
           <MenuItem value={1}>1</MenuItem>
           <MenuItem value={2}>2+</MenuItem>
          </SettlementFormSelect>
         </SettlementFormControlFullWidth>
        </Grid>

        <Grid xs={3.75} marginTop={1} item>
         <SettlementFormControlFullWidth>
          <SettlementFormInputLabel shrink>Residents</SettlementFormInputLabel>
          <SettlementFormSelect
           name='residents'
           value={formResponse.residents}
           required
           onChange={handleInputChange}>
           <MenuItem value='1'>1</MenuItem>
           <MenuItem value='2'>2</MenuItem>
           <MenuItem value='3'>3</MenuItem>
           <MenuItem value='4'>4</MenuItem>
           <MenuItem value='5'>5</MenuItem>
          </SettlementFormSelect>
         </SettlementFormControlFullWidth>
        </Grid>

        <Grid xs={3.75} marginTop={1} item>
         <SettlementFormControlFullWidth>
          <SettlementFormInputLabel shrink>65+</SettlementFormInputLabel>
          <SettlementFormSelect
           name='residents65'
           labelId='residents65-label'
           required
           id='residents65-select'
           value={formResponse.residents65}
           onChange={handleInputChange}>
           <MenuItem value='1'>1</MenuItem>
           <MenuItem value='2'>2</MenuItem>
           <MenuItem value='3'>3</MenuItem>
           <MenuItem value='4'>4</MenuItem>
           <MenuItem value='5'>5</MenuItem>
          </SettlementFormSelect>
         </SettlementFormControlFullWidth>
        </Grid>
       </Grid>
      </SettlementFormSectionContainer>
     </Grid>
     <Grid item xs={12} md={4}>
      <SettlementFormSectionContainer>
       <SettlementFormSectionTitle>
        Other Liabilities
       </SettlementFormSectionTitle>
       {formResponse.otherLiabilities.map((liability, index) => (
        <Box key={index} sx={{ mb: 2 }}>
         <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
           <SettlementFormControlFullWidth>
            <SettlementFormInputLabel shrink>
             Type of Liability
            </SettlementFormInputLabel>
            <SettlementFormSelect
             name='type'
             value={liability.type}
             onChange={(e) => handleLiabilitiesInputChange(e, index)}
             fullWidth>
             <MenuItem value='State Tax Debt'>State Tax Debt</MenuItem>
             <MenuItem value='Alimony/Child Support'>
              Alimony/Child Support
             </MenuItem>
             <MenuItem value='Student Loans'>Student Loans</MenuItem>
             <MenuItem value='Credit Card'>Credit Card</MenuItem>
             <MenuItem value='Private Lender Loans'>
              Private Lender Loans
             </MenuItem>
            </SettlementFormSelect>
           </SettlementFormControlFullWidth>
          </Grid>
          <Grid item xs={12} md={12}>
           <SettlementFormControlFullWidth>
            <SettlementFormInputLabel shrink>
             Total Liability
            </SettlementFormInputLabel>
            <SettlementFormTextField
             type='number'
             name='totalLiability'
             value={liability.totalLiability}
             onChange={(e) => handleLiabilitiesInputChange(e, index)}
             fullWidth
            />
           </SettlementFormControlFullWidth>
          </Grid>
          <Grid item xs={12} md={12}>
           <SettlementFormControlFullWidth>
            <SettlementFormInputLabel shrink>
             Monthly Payment
            </SettlementFormInputLabel>
            <SettlementFormTextField
             type='number'
             name='monthlyPayment'
             value={liability.monthlyPayment}
             onChange={(e) => handleLiabilitiesInputChange(e, index)}
             fullWidth
            />
           </SettlementFormControlFullWidth>
          </Grid>
          {liability.type === "Credit Card" && (
           <Grid item xs={12} md={12}>
            <SettlementFormControlFullWidth>
             <SettlementFormInputLabel shrink>
              Available Credit
             </SettlementFormInputLabel>
             <SettlementFormTextField
              type='number'
              name='availableCredit'
              value={liability.availableCredit}
              onChange={(e) => handleLiabilitiesInputChange(e, index)}
              fullWidth
             />
            </SettlementFormControlFullWidth>
           </Grid>
          )}
         </Grid>
         <IconButton onClick={() => handleDeleteLiability(index)} color='error'>
          <Delete />
         </IconButton>
        </Box>
       ))}

       <Button variant='outlined' onClick={handleAddLiability}>
        Add Liability
       </Button>
      </SettlementFormSectionContainer>
     </Grid>
     <Grid item xs={12} md={4}>
      <SettlementFormSectionContainer>
       <SettlementFormSectionTitle>Assets</SettlementFormSectionTitle>
       {formResponse.assets.map((asset, index) => (
        <Box key={index} sx={{ mb: 2 }}>
         <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
           <SettlementFormControlFullWidth>
            <SettlementFormInputLabel shrink>
             Type of Asset
            </SettlementFormInputLabel>
            <SettlementFormSelect
             name='type'
             value={asset.type}
             onChange={(e) => handleAssetsInputChange(e, index)}
             fullWidth>
             <MenuItem value='Non-Primary Residence'>
              Non-Primary Residence
             </MenuItem>
             <MenuItem value='Retirement Account'>Retirement Account</MenuItem>
             <MenuItem value='Savings Account'>Savings Account</MenuItem>
             <MenuItem value='Stocks and Bonds'>Stocks and Bonds</MenuItem>
             <MenuItem value='Non-Primary Cars and Trucks'>
              Non-Primary Cars and Trucks
             </MenuItem>
             <MenuItem value='Recreational Vehicles'>
              Recreational Vehicles
             </MenuItem>
            </SettlementFormSelect>
           </SettlementFormControlFullWidth>
          </Grid>
          <Grid item xs={12} md={12}>
           <SettlementFormControlFullWidth>
            <SettlementFormTextField
             type='number'
             name='estimatedValue'
             label='Estimated Value'
             value={asset.estimatedValue}
             onChange={(e) => handleAssetsInputChange(e, index)}
             fullWidth
            />
           </SettlementFormControlFullWidth>
          </Grid>
          <Grid item xs={12} md={12}>
           <SettlementFormControlFullWidth>
            <SettlementFormTextField
             type='number'
             name='liabilityOnAsset'
             label='Liability On Asset'
             value={asset.liabilityOnAsset}
             onChange={(e) => handleAssetsInputChange(e, index)}
             fullWidth
            />
           </SettlementFormControlFullWidth>
          </Grid>
         </Grid>
         <IconButton onClick={() => handleDeleteAsset(index)} color='error'>
          <Delete />
         </IconButton>
        </Box>
       ))}
       <Button variant='outlined' onClick={handleAddAsset}>
        Add Asset
       </Button>
      </SettlementFormSectionContainer>
     </Grid>
    </Grid>
    <SettlementFormSectionContainer>
     <SettlementFormButton type='submit' variant='contained' color='primary'>
      Calculate Settlement
     </SettlementFormButton>
    </SettlementFormSectionContainer>
   </form>
  </SettlementFormContainer>
 );
};

export default SettlementForm;
