import React, { useState, useContext } from "react";
import ProfileContext from "../../context/profile/profileContext";
import { Card, Button, Typography, Grid, Box } from "@mui/material";
import CSVReader from "react-csv-reader";
import Pagination from "./Pagination";
const UpdateAllClients = () => {
 const { fetchAllProfiles, updateProfiles, updateList } =
  useContext(ProfileContext);
 const [clientUpdates, setClientUpdates] = useState([]);

 // Fetch profiles and prepare them for updates
 const initiateUpdateProcess = async () => {
  fetchAllProfiles();
  const updates = updateList.map((profile) => ({
   clientId: profile._id,
   fullName: profile.fullName, // Adjust according to your profile object structure
   data: null,
  }));
  setClientUpdates(updates);
 };

 const handleFileLoaded = (data, index) => {
  // Update the specific entry with loaded CSV data
  const updatedClientUpdates = [...clientUpdates];
  updatedClientUpdates[index].data = data;
  setClientUpdates(updatedClientUpdates);
 };

 const onSubmit = async () => {
  // Handle submission of updates here
  console.log("Submitting updates for", clientUpdates.length, "clients");
  // Example: You would iterate over clientUpdates and call updateProfiles for each
 };

 const [currentPage, setCurrentPage] = useState(1);
 const postsPerPage = 10;

 const indexOfLastPost = currentPage * postsPerPage;
 const indexOfFirstPost = indexOfLastPost - postsPerPage;
 const currentPosts = updateList.slice(indexOfFirstPost, indexOfLastPost);

 const paginate = (pageNumber) => setCurrentPage(pageNumber);

 console.log(clientUpdates, "clientUpdates");
 console.log(updateList, "updateList");
 console.log(currentPosts, "currentPosts");
 return (
  <Card>
   <Box padding={2}>
    <Typography variant='h5' marginBottom={2}>
     Update All Clients
    </Typography>
    <Button
     onClick={initiateUpdateProcess}
     variant='contained'
     color='primary'
     sx={{ mb: 2 }}>
     Fetch Clients for Update
    </Button>
    <Grid container spacing={2}>
     <Pagination
      postsPerPage={postsPerPage}
      totalPosts={updateList.length}
      paginate={paginate}
     />
     {currentPosts.length > 0 &&
      currentPosts.map((profile) => (
       <Grid item xs={12} sm={6} key={profile._id}>
        <Typography>{profile.fullName}</Typography>
        <CSVReader
         label='Upload Account Transcripts'
         onFileLoaded={(data) => handleFileLoaded(data, index)}
         inpuId='profiles' // Ensuring unique ID
         inputStyle={{ color: "red" }}
        />
       </Grid>
      ))}
    </Grid>
    <Button
     onClick={onSubmit}
     variant='contained'
     color='primary'
     sx={{ mt: 2 }}>
     Update All
    </Button>
   </Box>
  </Card>
 );
};

export default UpdateAllClients;
