import React, { useState, useContext, useEffect } from "react";
import ProfileContext from "../../context/profile/profileContext";
import ProfileItem from "./ProfileItem";
import Pagination from "./Pagination";
import Upload from "./Upload";
import ProfilePage from "./ProfilePage";
import SearchComponent from "./SearchComponent";
import { Button, Grid, Typography, Paper, Box } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UpdateAllClients from "./UpdateAllClients";

const TaxTrackBackEnd = () => {
 const profileContext = useContext(ProfileContext);
 const { newProfile, clearProfile, profileList, profile } = profileContext;

 const [currentPage, setCurrentPage] = useState(1);
 const postsPerPage = 10;

 const indexOfLastPost = currentPage * postsPerPage;
 const indexOfFirstPost = indexOfLastPost - postsPerPage;
 const currentPosts = profileList.slice(indexOfFirstPost, indexOfLastPost);

 const paginate = (pageNumber) => setCurrentPage(pageNumber);

 const [returnState, setReturnState] = useState(false);
 useEffect(() => {
  if (newProfile != null) {
   setReturnState(true);
   const timer = setTimeout(() => {
    setReturnState(false);
    clearProfile();
   }, 5000);
   return () => clearTimeout(timer);
  }
 }, [newProfile, profileContext]);

 return (
  <Box sx={{ flexGrow: 1, backgroundColor: "#f5f5dc", padding: 2 }}>
   <Grid container spacing={2} alignItems='flex-start'>
    {/* Side by side components */}
    <Grid item xs={12} md={6}>
     <Paper elevation={3} sx={{ padding: 2, marginRight: 2 }}>
      <Upload />
     </Paper>
    </Grid>
    <Grid item xs={12} md={6}>
     <Paper elevation={3} sx={{ padding: 2 }}>
      <SearchComponent />
      <UpdateAllClients />
     </Paper>
     <Grid item xs={12}>
      <Pagination
       postsPerPage={postsPerPage}
       totalPosts={profileList.length}
       paginate={paginate}
      />
      {profile === null &&
       currentPosts.length > 0 &&
       currentPosts.map((profile) => (
        <ProfileItem key={profile._id} profile={profile} />
       ))}
     </Grid>
    </Grid>

    {/* Pagination and profiles */}
    <Grid item xs={12}>
     {profile != null && <ProfilePage />}
    </Grid>
   </Grid>
  </Box>
 );
};

export default TaxTrackBackEnd;
